import styled, { css } from "styled-components";

export const Row = styled.div`
  display: flex;
`;

export const Col = styled.div`
  flex: 1;
`;

export const MobileMask = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(255,255,255,1);
  width: 100%;
  height: 100%;
  display: none;
  @media (max-width: 991px){
    display: block;
  }
`;


export const Button = styled.button<{color: string, hover?: 'main' | 'other' | 'red' | 'secondary' | 'black'}>`
  background-color: ${props => props.color};
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  padding: 15px 50px;
  font-size: 13px;
  letter-spacing: 1px;
  border: 0px;
  margin: 10px 5px;
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  z-index: 1;
  display: flex;
  text-align: center;
  align-items: center;
  img{
    height: 15px;
    width: auto;
    margin-right: 10px;
  }
  @media (max-width: 991px){
    justify-content: center;
  }
  @media (max-width: 99xpx){
    flex: 0 49%;
    font-size: 11px;
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
    line-height: 40px;
    justify-content: center;
    &.button-mobile{
      flex: inherit;
      width: 100%;
    }
  }
  ${props => props.hover == "main" && css`
    
    &:hover{
      cursor: pointer;
      
    }
  `}

  ${props => props.hover == "secondary" && css`
    color: #000;
    background: #fff;
    img{
      filter: invert(1);
    }
    &:hover{
      
      cursor: pointer;
      img{
        filter: invert(0);
      }
    }
  `}

  ${props => props.hover == "black" && css`
    &:hover{
      background-color: #000;
      cursor: pointer;
      transform: scale(.95);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
  `}

  ${props => props.hover == "other" && css`
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    &:hover{
      background-color: #fff;
      cursor: pointer;
      color: #000;
    }
  `}

  ${props => props.hover == "red" && css`
    &:hover{
      background-color: #b12c23;
      cursor: pointer;
      transform: scale(.95);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
  `}
  
  &:last-child{
    margin-right: 0px;
  }
  &:focus{
    outline: 0px;
  }
`;

export const ButtonAddMessage = styled.button<{active?: boolean}>`
  background-color: #fff; 
  border: 1px solid #000;
  border-radius: 0px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  width: 100%;
  padding: 12px 50px;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
  transition: .3s ease-in-out;
  justify-content: center;
  z-index: 1;
  margin-bottom: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.14), 0 15px 12px rgba(0, 0, 0, 0.01);
  img{
    width: 25px;
    height: auto;
    transition: .3s ease-in-out;
  }
  @media (max-width: 991px){
    justify-content: center;
  }
  @media (max-width: 767px){
    font-size: 12px;
    padding: 12px 15px;
    text-align: center;
    justify-content: center;
  }
  &:hover{
    background-color: #333;
    color: #fff;
    cursor: pointer;
    transform: scale(.95);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    img{
      filter: invert(1);
    }
  }
  align-items: center;
  
  &:focus{
    outline: 0px;
  }
  display: none;
  ${props => props.active && css`
    display: flex;
  `}
`;

export const AlignCenter = styled.div`
  text-align: center;
`;

export const AlignRight = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767px){
    justify-content: center;
  }
`;

export const AlignLeft = styled.div`
  text-align: left;
`;